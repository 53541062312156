@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.cdnfonts.com/css/akrobat');
@import 'normalize.css';
/**SyncFusion*/
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

/** Defaults */
* {
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
}

html,
body {
  height: 100vh;
  width: 100vw;
  color: black;
  overflow: hidden;
  font-size: 14px;
  /** 1em ou 1rem === 14px */
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

/** Defaults */

/** centralizando swal */
.swal2-show {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  flex-direction: column;
}

.swal2-title {
  display: flex !important;
  align-items: center !important;
}

/** card-titulo */
.card-titulo {
  color: #000 !important;
  background: #fff !important;
}

/** content */
.content {
  box-shadow: inset -2px 0px 2px #00000012 !important;
}

/** DataTable */
.ag-row-selected {
  border-bottom: 1px solid #223771 !important;
  background-color: rgba(92, 120, 199, 0.22) !important;
}

.ag-row-odd:not(.ag-row-selected) {
  background-color: rgba(195, 210, 227, 0.12) !important;
  border-bottom: 1px solid #c3d2e3 !important;
}

.ag-row-even:not(.ag-row-selected) {
  background-color: #ffffff !important;
  border-bottom: 1px solid #c3d2e3 !important;
}

.no-overflow .ag-root-wrapper-body {
  height: auto !important;
}

/** botão de editar */
.button-grid {
  margin-right: 30px !important;
}

/** sombra da tabela */
.tabela .MuiPaper-elevation {
  box-shadow: none !important;
}

.predecessoras {
  overflow: hidden;
  padding-bottom: 22px;
}

.sem-border-bottom .ag-theme-material .ag-center-cols-clipper {
  border-bottom: none !important;
}

.predecessoras .ag-theme-material .ag-center-cols-clipper {
  overflow: auto;
}

/** borda paginação */
.MuiTableCell-root {
  border: none !important;
}

.ag-center-cols-viewport {
  height: calc(100% + 18px) !important;
}

/* fonte */
.ag-header-cell-text {
  font-family: 'Open Sans' !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
}

/** border-bottom da tabela */
.ag-theme-material {
  border-bottom: 2px solid #c3d2e3 !important;
  box-shadow: none !important;
}

.ag-cell-label-container {
  height: 100%;
  font-size: 14px !important;
  font-weight: 400;
  cursor: pointer;
  color: #95a2b9;
  opacity: 1 !important;
  margin-bottom: 11px !important;
}

.ag-header-row {
  border: none !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
  padding-top: 15px !important;
  height: 50px !important;
}

.MuiButton-containedPrimary .MuiButton-disableElevation {
  margin-right: 30px !important;
}

.ag-cell-not-inline-editing {
  color: #1b1d26 !important;
  font-family: 'Open Sans';
}

.orange-text {
  color: #E26200 !important;
}

.green-text {
  color: #18850C !important;
}

.ag-header-viewport {
  border-bottom: 2px solid #c3d2e3 !important;
  background-color: rgba(195, 210, 227, 0.25) !important;
  border-radius: 0px !important;
  border-top: 1px solid #c3d2e3 !important;
  height: 50px !important;
}

.ag-pinned-left-header {
  border-bottom: 2px solid #c3d2e3 !important;
  background-color: rgba(195, 210, 227, 0.25) !important;
  border-radius: 0px !important;
  border-top: 1px solid #c3d2e3 !important;
  height: 50px !important;
}

.resumo .ag-header-viewport {
  margin: 0px 0px !important;
}

.resumo .ag-body-viewport {
  margin: 0 0px !important;
}

.sem-margem .ag-root.ag-layout-normal {
  margin: 0 0px !important;
}

.sem-bordas .ag-root.ag-layout-normal {
  margin: 0 0 !important;
}

.ag-header-active {
  background-color: transparent !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  font-family: 'Open Sans' !important;
  color: #5b6478 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}

.ag-header {
  border: none !important;
  min-height: 50px !important;
  height: 50px !important;
}

.ag-root.ag-layout-normal {
  margin: 0px 23px !important;
}

.ag-paging-panel {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}

/** DataTable */

.suggestions {
  max-height: 400px !important;
}

.suggestions-xs-1-left {
  max-height: 280px !important;
  width: 25% !important;
  max-width: 65% !important;
  margin-left: 55% !important;
}

.suggestions-xs-1 {
  max-height: 280px !important;
  max-width: 25% !important;
}

.suggestions-xs-2 {
  max-height: 280px !important;
  max-width: 82% !important;
}

.suggestions-xs-2-left {
  max-height: 280px !important;
  width: 65% !important;
  max-width: 65% !important;
  margin-left: 320px !important;
}

.suggestions-xs-3 {
  max-height: 280px !important;
  width: 150% !important;
}

.suggestions-xs-4 {
  max-height: 200px !important;
  width: 210% !important;
}

.suggestions-xs-5 {
  max-height: 280px !important;
  width: 270% !important;
}

.suggestions-xs-6 {
  max-height: 280px !important;
  width: 350% !important;
}

/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(9, 9, 9, 0);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}

/* Custom Scrollbar */

.submenu span {
  font-size: 12px !important;
}

.fc-day-today {
  background-color: transparent !important;
}

label[id^="autocomplete-input"][data-shrink="false"] {
  max-width: calc(100% - 37px) !important;
}