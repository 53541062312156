.run-write-close {
  transition: opacity 0.5s linear;
  opacity: 0;
}

.run-write-open {
  transition: opacity 0.5s linear;
  opacity: 1;
}
.run-write-close-sucess {
  transition: opacity 0.5s linear;
  transition-delay: 0.5s;
  opacity: 1;
}

.run-write-open-sucess {
  opacity: 0;
}
